@import "./app/tamalelibs/resources/styles/_theme.scss";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import '@angular/cdk/overlay-prebuilt.css';
@import './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';
$accent: #1890ff;
$selected-bg: #86c3fc;
$hovered-gradient: #d9efff;
$button-bg: #1890ff;
$selected-text: #000000;
$hovered-border: #1890ff;
$hovered-bg: #d9efff;
@import "@progress/kendo-theme-default/scss/calendar";
@import "@progress/kendo-theme-default/scss/combobox";
@import "@progress/kendo-theme-default/scss/datetime";
@import "@progress/kendo-theme-default/scss/dialog";
@import "@progress/kendo-theme-default/scss/dropdownlist";
@import "@progress/kendo-theme-default/scss/dropdowntree";
@import "@progress/kendo-theme-default/scss/multiselect";
@import "@progress/kendo-theme-default/scss/popup";
@import "@progress/kendo-theme-default/scss/switch";
@import "@progress/kendo-theme-default/scss/treeview";
@import "@progress/kendo-theme-default/scss/tooltip";
/*** forala editor**/

@import "../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
@import "../node_modules/froala-editor/css/froala_style.min.css";

@import "~@progress/kendo-theme-default/dist/all.css";
@import "font-awesome/css/font-awesome.css";


@font-face {
    font-family: "SourceSansPro-Regular";
    src: url("./assets/fonts/SourceSansPro-Regular.ttf");
}

@font-face {
    font-family: "SourceSansPro-Light";
    src: url("./assets/fonts/SourceSansPro-Light.ttf");
}

@font-face {
    font-family: "SourceSansPro-SemiBold";
    src: url("./assets/fonts/SourceSansPro-SemiBold.ttf");
}

@font-face {
    font-family: "SourceSansPro-Bold";
    src: url("./assets/fonts/SourceSansPro-Bold.ttf");
}

@font-face {
    font-family: "SourceSansPro-It";
    src: url("./assets/fonts/SourceSansPro-Italic.ttf");
}

@font-face {
    font-family: "SourceSansPro";
    src: url("./assets/fonts/SourceSansPro-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SourceSansPro";
    src: url("./assets/fonts/SourceSansPro-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("./assets/fonts/OpenSans/OpenSans-Regular.ttf");
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

/* latin */
@font-face {
    font-family: "Calibri-Normal";
    src: url("./assets/fonts/Calibri/Calibri-Normal.woff2") format("woff2");
}

* {
    box-sizing: border-box;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

/* default the cursor of all elements with tooltip to pointer */
*[title] {
    cursor: pointer;
}

body {
    -webkit-font-smoothing: antialiased;
    font-family: "SourceSansPro-Regular";
    font-size: 16px;
    margin: 0;
}

input,
textarea,
select,
button {
    font-family: inherit;
    color: $color-tertiary-click ;
}

// highlight style supportted by mark.js
span.highlight {
    background-color: $color-text-highlight;
}

// Form
input.tam-textbox {
    border: 1px solid $color-border;
    border-radius: 2.5px;
    font-size: 14px;
    height: 32px;
    padding-left: 8px;

    &:hover {
        border: solid 1px $color-primary;
        box-shadow: 0px 0px 5px 0 rgba(48, 127, 226, 0.35);
    }

    &:focus {
        outline: none;
        border: solid 1px $color-primary;
        box-shadow: 0px 0px 5px 0 rgba(48, 127, 226, 0.35);
    }
}

input {
    &:hover {
        border: solid 1px $color-primary;
    }

    &:focus {
        outline: none;
        border: solid 1px $color-primary;
    }
}

textarea.tam-textbox {
    border-radius: 2.5px;
    border: solid 1px $color-border;
    font-size: 14px;
    height: auto;
    min-height: 32px;
    padding: 6px 0 0 8px;
    resize: vertical;

    &.fixed-size-textarea {
        resize: none;
    }

    &:hover {
        border: solid 1px $color-primary;
        box-shadow: 0px 0px 5px 0 rgba(48, 127, 226, 0.35);
    }

    &:focus {
        outline: none;
        border: solid 1px $color-primary;
        box-shadow: 0px 0px 5px 0 rgba(48, 127, 226, 0.35);
    }
}

.tam-form-field {
    font-size: 14px;

    .tam-form-label {
        color: $color-caption;
        margin-bottom: 4px;
    }

    textarea.tam-textbox {
        height: 100px;
    }

    .tam-textbox {
        margin-bottom: 20px;
        width: 100%;
        color: $color-tertiary-click;
    }

    .tam-form-help {
        font-size: 12px;
        margin-top: -20px;
    }

    &.has-error {
        .tam-textbox {
            border-color: $color-border-error;
        }

        .tam-form-help {
            color: $color-error;
            height: 20px;
        }
    }

    .fr-box.fr-basic.fr-top.field-disabled .fr-wrapper {
        border-top: 1px solid $LightGray3;
    }
}

// override the margin-bottom when field components display in the formbuilder.
control-shell .tam-form-field .tam-textbox {
    margin-bottom: 0;
}

// Button
.tam-loading {
    display: inline-block;
    padding: 0;
    margin: 0 auto 0 auto;
    border: 0.1em solid $color-background-primary;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    -webkit-animation: load-animate 1s infinite linear;
    animation: load-animate 1s infinite linear;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.tam-loading-gray {
    border: 0.1em solid $color-tertiary;
    border-top-color: transparent;
    border-left-color: transparent;
}

@-webkit-keyframes load-animate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load-animate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.tam-btn {
    background-color: transparent;
    background-image: none;
    border-radius: 2.5px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 30px;
    padding: 0 16px;
    position: relative;
    text-align: center;

    // &,
    // &:active,
    // &:focus {
    //     outline: 0;
    //     outline-color: initial;
    //     outline-style: initial;
    //     outline-width: 0px;
    // }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active {
        background-color: #eee !important;
        border: 1px solid #bfbfbf !important;
        color: #9d9d9d !important;
        cursor: not-allowed !important;
    }

    &.tam-btn-sm {
        padding: 0 8px;
        height: 22px;
    }

    &.tam-btn-lg {
        font-size: 16px;
        height: 38px;
    }

    .tam-btn-loading {
        align-items: center;
        border-radius: 2.5px;
        bottom: 0;
        cursor: not-allowed;
        display: flex;
        left: 0;
        margin: -1px;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.tam-btn-primary {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: $color-background-primary;

    &.tam-btn-invert {
        background-color: transparent;
        border-color: $color-primary;
        color: $color-primary;

        &:hover {
            background-color: transparent;
            border-color: $color-primary-hover;
            color: $color-primary-hover;
        }

        &:active {
            background-color: transparent;
            border-color: $color-primary-click;
            color: $color-primary-click;
        }

        & .tam-btn-loading {
            background-color: transparent;
            border: 1px solid $color-primary-hover;

            &+span {
                visibility: hidden;
            }

            .tam-loading {
                border-bottom-color: $color-primary-hover;
                border-right-color: $color-primary-hover;
            }
        }
    }

    &:hover {
        background-color: $color-primary-hover;
        border: 1px solid $color-primary-hover;
    }

    &:active {
        background-color: $color-primary-click;
        border: 1px solid $color-primary-click;
    }

    & .tam-btn-loading {
        background-color: $color-primary-hover;

        .tam-loading {
            border-bottom-color: $color-background-primary;
            border-right-color: $color-background-primary;
        }
    }
}

.tam-btn-primary-success {
    background-color: $color-primary-success;
    border: 1px solid $color-primary-success;
    color: $color-background-primary-success;

    &.tam-btn-invert {
        background-color: transparent;
        border-color: $color-primary-success;
        color: $color-primary-success;

        &:hover {
            background-color: transparent;
            border-color: $color-primary-success-hover;
            color: $color-primary-success-hover;
        }

        &:active {
            background-color: transparent;
            border-color: $color-primary-success-click;
            color: $color-primary-success-click;
        }

        & .tam-btn-loading {
            background-color: transparent;
            border: 1px solid $color-primary-success-hover;

            &+span {
                visibility: hidden;
            }

            .tam-loading {
                border-bottom-color: $color-primary-success-hover;
                border-right-color: $color-primary-success-hover;
            }
        }
    }

    &:hover {
        background-color: $color-primary-success-hover;
        border: 1px solid $color-primary-success-hover;
    }

    &:active {
        background-color: $color-primary-success-click;
        border: 1px solid $color-primary-success-click;
    }

    & .tam-btn-loading {
        background-color: $color-primary-success-hover;

        .tam-loading {
            border-bottom-color: $color-background-primary-success;
            border-right-color: $color-background-primary-success;
        }
    }
}

.tam-btn-secondary {
    background-color: $color-background-primary;
    border: 1px solid $color-secondary;
    color: $color-secondary;
    padding: 0 15px;

    &:hover {
        border-color: $color-secondary-hover;
        color: $color-secondary-hover;
    }

    &:active {
        border-color: $color-secondary-click;
        color: $color-secondary-click;
    }

    & .tam-btn-loading {
        background-color: $color-background-primary;
        border: 1px solid $color-secondary-hover;

        .tam-loading {
            border-bottom-color: $color-secondary-hover;
            border-right-color: $color-secondary-hover;
        }
    }
}

.tam-btn-tertiary {
    background-color: $color-background-primary;
    border: 1px solid $color-tertiary-border;
    color: $color-tertiary;
    padding: 0 15px;

    &:hover {
        color: $color-tertiary-hover;
    }

    &:active {
        color: $color-title;
    }

    & .tam-btn-loading {
        background-color: $color-background-primary;
        border: 1px solid $color-tertiary-border;

        .tam-loading {
            border-bottom-color: $color-tertiary-hover;
            border-right-color: $color-tertiary-hover;
        }
    }
}

.tam-btn-destructive {
    background-color: $color-error;
    border: 1px solid $color-error;
    color: $color-background-primary;
    padding: 0 16px;

    &:hover {
        background-color: $color-error-hover;
        border: 1px solid $color-error-hover;
    }

    &:active {
        background-color: $color-error-click;
        border: 1px solid $color-error-click;
    }

    &.tam-btn-invert {
        background-color: transparent;
        border-color: $color-error;
        color: $color-error;

        &:hover {
            background-color: transparent;
            border-color: $color-error-hover;
            color: $color-error-hover;
        }

        &:active {
            background-color: transparent;
            border-color: $color-error-click;
            color: $color-error-click;
        }

        & .tam-btn-loading {
            background-color: transparent;
            border: 1px solid $color-error-hover;

            &+span {
                visibility: hidden;
            }

            .tam-loading {
                border-bottom-color: $color-error-hover;
                border-right-color: $color-error-hover;
            }
        }
    }

    & .tam-btn-loading {
        background-color: $color-error-hover;

        .tam-loading {
            border-bottom-color: $color-background-primary;
            border-right-color: $color-background-primary;
        }
    }
}

// Check Box
.tam-checkbox-wrapper {
    align-items: center;
    display: inline-flex;
    font-size: 14px;

    .tam-checkbox {
        border: 1px solid #808080;
        border-radius: 2px;
        color: $black;
        cursor: pointer;
        display: block;
        height: 16px;
        position: relative;
        width: 16px;
        min-width: 16px;

        &:hover {
            border-color: #1890ff;
        }

        & input {
            display: none;
        }

        .tam-checkbox-disabled,
        .tam-checkbox-disabled+span {
            cursor: not-allowed;
        }

        &.tam-checkbox-disabled {
            border-color: #bfbfbf;
            background-color: #f8f8f8;
        }

        &.tam-checkbox-checked.tam-checkbox-disabled,
        &.tam-checkbox-checked.tam-checkbox-disabled:hover {
            background-color: #bfbfbf;
            border-color: #bfbfbf;
        }

        &.tam-checkbox-checked {
            background-color: $color-primary-hover;
            border-color: $color-primary-hover;

            &:hover {
                background-color: #1890ff;
                border-color: #1890ff;
            }
        }

        &.tam-checkbox-checked:after {
            background: transparent;
            border: 2px solid $color-background-primary;
            border-top: none;
            border-right: none;
            content: "";
            height: 4px;
            left: 50%;
            margin-top: -4px;
            margin-left: -5px;
            opacity: 1;
            position: absolute;
            top: 50%;
            transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            width: 8px;
        }

        &.tam-checkbox-indeterminate {
            background-color: $color-primary-hover;
            border-color: $color-primary-hover;
        }

        &.tam-checkbox-indeterminate:after {
            content: "";
            position: absolute;
            width: 8px;
            height: 2px;
            top: 6px;
            left: 3px;
            background-color: $color-background-primary;
        }
    }

    .tam-checkbox-content {
        margin-left: 8px;
    }

    .tam-checkbox-content.ml-4 {
        margin-left: 4px;
    }
}

/*******************TextBox************************/

.common-input {
    color: $color-tertiary-click;

    &:hover {
        border: solid 1px $color-primary;
    }

    &:focus {
        outline: none;
        border: solid 1px $color-primary;
    }
}

.tam-input {
    width: 240px;
    height: 32px;
    font-size: 14px;
    padding-left: 8px;
    border-radius: 2.5px;
    border: solid 1px #ebebeb;
    color: $color-tertiary-click;

    &:hover {
        border: solid 1px $color-primary;
    }

    &:focus {
        outline: none;
        border: solid 1px $color-primary;
    }
}

.tam-input-disabled {
    color: #808080;
    background-color: $color-background-secondary;

    &:hover {
        border: solid 1px #bfbfbf;
        box-shadow: none;
        cursor: not-allowed;
    }
}

.tam-input-tip {
    .tip-label {
        font-size: 14px;
        height: 18px;
        color: $black;

        span {
            color: $color-error;
        }
    }

    .tam-input-bad {
        border-radius: 2.5px;
        border: 1px solid $color-error;

        &:hover {
            border: 1px solid $color-error;
            box-shadow: 0px 0px 5px 0 rgba(245, 50, 73, 0.35);
        }

        &:focus {
            outline: none;
            border: 1px solid $color-error;
            box-shadow: 0px 0px 5px 0 rgba(245, 50, 73, 0.35);
        }
    }

    .error-label {
        height: 18px;
        width: 240px;
        display: inline-block;
        font-size: 14px;
        color: $color-error;
    }
}

/*********************Textarea************************************/

textarea {
    width: 240px;
    height: 32px;
    min-height: 32px;
    border-radius: 3px;
    border: solid 1px #ccc;
    font-size: 14px;
    padding: 0;
    padding-left: 8px;
    padding-top: 6px;
    resize: vertical;

    &:hover {
        border: solid 1px $color-primary;
        box-shadow: 0px 0px 5px 0 rgba(48, 127, 226, 0.35);
    }

    &:focus {
        outline: none;
        border: solid 1px $color-primary;
        box-shadow: 0px 0px 5px 0 rgba(48, 127, 226, 0.35);
    }
}

textarea::placeholder {
    color: $color-placeholder;
}

textarea::-webkit-input-placeholder {
    color: $color-placeholder;
}

textarea:-moz-placeholder {
    color: $color-placeholder;
}

textarea:-ms-input-placeholder {
    color: $color-placeholder;
}

// Context Menu
context-menu-content .ngx-contextmenu {
    background-color: $color-background-primary;
    border: 1px solid $color-background-primary;
    border-radius: 2.5px;
    box-shadow: 1px 1px 5px 1px $color-box-shadow;
    min-width: 150px;

    ul[role="menu"].ngx-contextmenu--dropdown-menu {
        font-family: "SourceSansPro-Regular" !important;
        display: block;
        list-style-type: none;
        list-style-image: none;
        margin: 0;
        padding: 0;

        :hover {
            background-color: $color-item-hovering;
        }

        // override base.scss of contextmenu
        --ngx-contextmenu-border: none !important;
        --ngx-contextmenu-box-shadow: none !important;
        --ngx-contextmenu-item-separator-color: none !important;
        --ngx-contextmenu-item-separator-padding: none !important;
        --ngx-contextmenu-item-background-hover-color: none !important;
        --ngx-contextmenu-item-text-hover-color: none !important;

        &:focus {
            outline: none;
        }

        // to fix TAM-42554 after upgrading to @perfectmemory/ngx-contextmenu
        .ngx-contextmenu--parent-menu {
            &:after {
                content: none !important;
            }
        }

        li {
            display: list-item !important;

            .passive {
                padding: 0;
            }

            .ngx-contextmenu--item-content {
                align-items: center;
            }

            button,
            span.ngx-contextmenu--item-content>div {
                color: $black;
                cursor: pointer;
                display: flex;
                font-size: 14px;
                width: 100%;
                height: 30px;
                text-align: left;
                line-height: 30px;
                padding: 0 10px 0 12px;
                position: relative;
                white-space: nowrap;
                text-decoration: none;

                &:hover {
                    background-color: $color-item-hovering;
                }
            }

            &.disabled button,
            &.disabled button:hover,
            &.disabled span.passive>div,
            &.disabled span.passive>div:hover {
                color: #bfbfbf;
                cursor: not-allowed;
            }

            &.divider {
                background-color: $color-divider;
                cursor: default;
                height: 1px;
            }
        }
    }
}

context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu>li>span[role=menuitem] {
    padding: unset !important;
}

// override base.scss of ngx-contextmenu
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu>li>[role=menuitem]:not(.disabled):not(.ngx-contextmenu--item-content-passive):hover {
    color: $black !important;
}

/************** Kendo combobox*********************/

// set color for placeholder of kendo combobox
kendo-combobox {
    .k-searchbar ::placeholder {
        color: $color-placeholder;
    }
}

/************** Kendo combobox*********************/

/************** Kendo Switch*********************/

.k-switch {
    font-size: 9px;
}

.k-switch-on {
    .k-switch-container {
        background-color: #1890ff;
        width: 6.1em;
    }

    & .k-switch-handle,
    &:hover .k-switch-handle {
        background-color: $color-background-primary;
        border: none;
        width: 2.4em;
        height: 2.4em;
        margin: 0.2em 0;
        left: 3.5em;
    }
}

.k-switch-off {
    .k-switch-container {
        background-color: $secondaryGrey;
        width: 6.1em;

        &:hover {
            background-color: #ddd;
        }
    }

    & .k-switch-handle,
    &:hover .k-switch-handle,
    &.k-switch.k-state-disabled .k-switch-handle {
        background-color: $color-background-primary;
        border: none;
        width: 2.4em;
        height: 2.4em;
        margin: 0.2em 0;
        left: 0.2em !important;

        left: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

::ng-deep kendo-switch {
    .k-switch-handle {
        transform: translateX(0px) !important;
    }

    .k-switch-label-on,
    .k-switch-label-off {
        width: auto !important;
        font-size: 12px;
        font-weight: bold;
    }

    .k-switch-label-on {
        left: 9px;
    }

    .k-switch-label-off {
        right: 9px;
    }
}

.k-switch:hover .k-switch-container,
.k-switch.k-state-hover .k-switch-container {
    box-shadow: inset 0 0 0 0;
}

.k-switch:focus .k-switch-container,
.k-switch.k-state-focused .k-switch-container {
    box-shadow: inset 0 0 0 0;
}

.k-switch-container {
    box-shadow: inset 0 0 0 0;
}

.k-switch-label-on,
.k-switch-label-off {
    font-size: 12px;
    width: auto !important;
    color: $color-background-primary;
    color: #ffffff;
    font-family: "SourceSansPro";
    font-weight: normal !important;
    text-transform: none !important;
}

.k-switch-handle {
    background-clip: initial;
}

// small without label
.kendo-switch-small-no-label {
    width: 28px !important;
    height: 16px;
    border-radius: 28px;
    margin: 0;

    &.k-switch-on .k-switch-handle,
    .k-switch-on:hover .k-switch-handle {
        width: 12px;
        height: 12px;
        margin: 2px 0;
        left: 14px;
    }

    &.k-switch-off .k-switch-handle,
    .k-switch-off:hover .k-switch-handle,
    .k-switch-off.k-switch.k-state-disabled .k-switch-handle {
        width: 12px;
        height: 12px;
        margin: 2px 0;
        left: 2px !important;
    }

    &.k-switch-on .k-switch-container {
        width: 28px;
    }

    .k-switch-label-on,
    .k-switch-label-off {
        visibility: hidden;
    }
}

.kendo-switch-medium-with-label {
    width: 55px !important;
    height: 24px;
    border-radius: 28px;
    margin: 0;

    &.k-switch-on .k-switch-handle,
    .k-switch-on:hover .k-switch-handle {
        width: 20px;
        height: 20px;
        margin: 2px 0;
        left: 33px;
    }

    &.k-switch-off .k-switch-handle,
    .k-switch-off:hover .k-switch-handle,
    .k-switch-off.k-switch.k-state-disabled .k-switch-handle {
        width: 20px;
        height: 20px;
        margin: 2px 0;
        font-size: 14px;
        left: 2px !important;
    }

    &.k-switch-on .k-switch-container {
        width: 28px;
    }

    &.k-switch-label-on,
    .k-switch-label-off {
        font-size: 14px;
    }
}

// small without label

/************** Kendo Switch*********************/

// Below 2 styles will reset 2 kendo element's border-radius. If only set 1 of them, the style will not work
div.k-widget.k-window.k-dialog {
    border-radius: 2.5px;
    overflow: hidden;
}

.dialog,
div.k-window {
    padding: 0;
    border: 2px solid #f2f2f2;
}

div.k-content.k-window-content.k-dialog-content {
    border-radius: 2.5px;
    padding: 0px;
}


// Below style will change the color value of kendo-dropdown,kendo-dropdowntree,kendo-combobox,kendo-multiselect,
// kendo-dateinput and froala-editor to be the same.
.k-dropdown .k-dropdown-wrap,
.k-dropdown .k-dropdown-wrap .k-input,
.k-dropdowntree .k-dropdown-wrap .k-input,
.k-combobox .k-dropdown-wrap .k-input,
.k-multiselect .k-multiselect-wrap,
.k-multiselect .k-multiselect-wrap .k-input,
.k-dateinput .k-dateinput-wrap .k-input,
.fr-box.fr-basic .fr-element {
    color: $color-tertiary-click;
}

///////////////////////////////////////
//
// modal dialog style (body footer)
//
/////////////////////////////////////
// Kendo Date Picker
.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
    background: transparent;
}

.k-dropdown .k-dropdown-wrap {
    height: 32px;

    &:hover {
        background: transparent;
    }
}

.k-datepicker {
    height: 32px;
    border-radius: 2.5px;

    &:hover span {
        border-color: $color-primary;

        .k-select {
            background: transparent;
        }

        .k-icon {
            color: $color-primary;
        }
    }
}

.k-picker-wrap {
    :disabled {
        background-color: #f2f2f2;
    }
}

.k-dateinput-wrap {
    &:hover {
        border-color: #1890ff;
    }
}

.k-datepicker .k-select {
    background: transparent;
    border-left-width: 1px;
}

///////////////////////////////////////
//
// custom layout style
//
/////////////////////////////////////
.tml-tpl-ctrl-wrap {
    display: flex;
    flex-direction: row;
    padding-top: 2px;
    padding-bottom: 2px;
}

//////////////////////////////////
// use for one layer template layout
.tml-tpl-ctrl-wrap-vertical {
    display: flex;
    flex-direction: column;
    padding-top: 2px;
    padding-bottom: 2px;
}

.one-layer-tml-tpl-label {
    height: 20px !important;
    line-height: 20px !important;
}

//////////////////////////////

.tml-tpl-ctrl-wrap .tml-tpl-label,
.tml-tpl-ctrl-wrap .tml-tpl-input {
    /*border: 1px solid blue;*/
    // float: left;
    display: flex;
    flex-direction: row;
}

.tml-tpl-ctrl-wrap .tml-tpl-input .mdl-input {
    overflow: hidden;
}

.tml-tpl-ctrl-wrap .tml-tpl-input .mdl-input input {
    width: 100%;
}

.tml-tpl-ctrl-wrap .tml-tpl-input .dependent-value {
    padding: 0 5px;
    height: 32px;
    line-height: 32px;
}

.tml-tpl-ctrl-wrap .tml-tpl-label {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
}

.tml-tpl-ctrl-wrap .tml-tpl-label label {
    margin: 0px;
}

.tml-tpl-ctrl-wrap .checkbox {
    margin-top: 7px;
    margin-bottom: 7px;
}

.tml-tpl-group-wrap {
    border: 1px solid #d1d1d1;
    margin: 0px;
}

.tml-tpl-group-wrap .panel-heading {
    background-color: #f2f2f2;
    border-bottom: 1px solid #d1d1d1;
    color: black;
    padding: 5px 15px;
}

.tml-tpl-group-wrap .panel-heading label {
    color: #676a6c;
    font-size: 12px;
    margin: 0px;
}

.tml-tpl-group-wrap .panel-body {
    padding: 0px;
}

.k-textbox[readonly] {
    background-color: #eee;
    opacity: 1;
}

.k-textbox[readonly]:hover {
    background-color: #eee;
    opacity: 1;
}

///////////////////////////////////////////////////////
//
//  scroll bar
//
///////////////////////////////////////////////////////
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb {
    background-color: #808080;
}

::-webkit-scrollbar-thumb:window-inactive {
    background-color: #bfbfbf;
}

.k-multiselect .k-button {
    color: $pureWhite;
    border-color: #357edb;
    background-color: #428bca;
    background: #428bca;
    height: 26px;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.k-multiselect-wrap li {
    span {
        max-width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
    }
}

.k-list {
    background: $color-background-primary;
}

.k-icon {
    //TODO change the angle-down icon
    //   height: 12px;
    //   position: absolute;
    //   border-left: 1px solid #999;
    //   border-bottom: 1px solid #999;
    //   -webkit-transform: translate(0,-150%) rotate(-135deg);
    //   transform: translate(0,-150%) rotate(-135deg);
}

.k-multiselect .k-button {
    border-radius: 4px;
}

.k-widget.form-control .k-dropdown-wrap {
    border: 1px solid #ccc;
}

.k-widget.form-control .k-input {
    height: 28px;
}

///////////////////////////////////////////////////////
//
//  kendo-datepicker
//
///////////////////////////////////////////////////////

///////////////////////////////////////////////////////
//
//  For Filter Dialog usage only.
/// Added by Alan Yang 1/4/2019.
//  Note:
//  if put it in Filter components, The css is filter-body-container[_ngcontent-c9] .k-button-icon[_ngcontent-c9]
//  .k-button[_ngcontent-c9]. Since _ngcontent-c9 is generated by Angular, we can't locate the element.
//  Discussed with Jiu, put below code in style.scss for now.
//
///////////////////////////////////////////////////////
.filter-body-container {
    kendo-dropdownbutton {
        .k-button {
            border-style: none;
            width: 24px;
            height: 23.7px;
            padding: 0px;
            background-image: none;
            background-color: $color-background-primary;
        }
    }

    .k-button-icon .k-button {
        background-image: url("./assets/images/plus.svg");
        background-color: $color-background-primary;
    }
}

///////////////////////////////////////////////////////
//
//  entities link on recent note
//
///////////////////////////////////////////////////////
.item-entities-Link {
    text-decoration: none;
}

.item-entities-Link:hover {
    text-decoration: underline;
}

// relationship tree
kendo-treeview.k-widget.k-treeview> :first-child> :first-child {
    padding-left: 8px;
}

kendo-checkbox.k-checkbox-wrapper.ng-star-inserted {
    display: flex;
    align-items: center;
}

// entry template entities/MEX
kendo-taglist>ul>li.k-button.ng-star-inserted {
    background-color: $color-link;
}

// new Entity for Extra information

.ng2-tag-input.foundation-theme tag:hover {
    background-color: $color-primary-hover !important;
}

.ng2-tag-input.foundation-theme tag:hover delete-icon {
    display: block;
}

.ng2-tag-input.foundation-theme tag delete-icon {
    display: none;
}

// multi sel dropdown with filter checkbox span
.multi-sel-dropdown-constainer {
    .label-container {
        .tam-checkbox-content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
        }

        &:hover {
            background-color: $color-item-hovering;
        }
    }
}

// Kendo numberic input
span.k-numeric-wrap {
    border-color: #ebebeb;
}

// get the inner wrapper borderless as there's already a 1px border out of this wrapper.
kendo-numerictextbox.k-numerictextbox span.k-numeric-wrap {
    border-width: 0;
}

// template
.k-multiselect-wrap.k-floatwrap {
    border-radius: 2.5px;
}

.k-dropdown-wrap {
    border-radius: 2.5px;
}

.k-picker-wrap {
    border-radius: 2.5px;
}

// template end

/* reset all kendo popup list item focus, selected, and hover styles */

kendo-popup {
    kendo-list {

        /** TAM-31110 item shouldn't hightlight after remove **/
        .k-state-focused {
            box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0.13) !important;
            /** TAM-32671 User able to use arrow down/up to highlight dropdown items, hit Enter to select it.**/
            background-color: $color-item-hovering !important;
        }

        /* The first item should be selected by default[TAM-26522]*/
        .k-state-focused:first-child {
            background-color: $color-item-hovering !important;
        }

        .k-state-selected,
        li:hover {
            background-color: $color-item-hovering !important;
        }
    }
}

/* dialog header style */
.template-dialog-header {
    background: $color-theme !important;

    .dialog-title {
        float: left;
        font-size: 18px;
        color: $pureWhite;
        flex: 1;
    }

    .dialog-close {
        float: right;
        font-size: 18px;
    }
}

.gray-block {
    position: fixed;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 8% 16%;
    background-color: rgba(0.2, 0.2, 0.2, 0.2);
    visibility: hidden;

    .dash-border {
        width: 100%;
        min-width: 300px;
        height: 100%;
        min-height: 400px;
        border-radius: 16px;
        border: dashed 3px black;

        .dark-box {
            width: 100%;
            height: 100%;
            background-color: rgba(0.8, 0.8, 0.8, 0.8);
            border-radius: 14px;
            text-align: center;
            vertical-align: middle;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                font-size: 280px;
                display: inline-block;
            }

            .text {
                margin-top: 20px;
                font-size: 28px;
            }

            .icon-container {
                visibility: collapse;
                position: absolute;
            }

            .attachments-container {
                width: 100%;
                padding: 8% 16%;
                visibility: collapse;

                .inline-edit-label {
                    color: white;
                    font-size: 20px;
                }

                .inline-edit-label:focus {
                    border: none;
                }

                .inline-edit-label:disabled {
                    border: none;
                }
            }
        }
    }
}

.simple-search-popup {
    .k-popup {
        background-color: $White;
    }

    .right-content {
        padding: 0px !important;
    }

    .item {
        width: 100% !important;
        height: 100% !important;
        margin: 0px !important;
        padding: 8px 8px 8px 12px !important;
        border-bottom: solid 1px $color-divider;

        .icon-container {
            padding-top: 11px !important;
            margin-left: -6px;
            margin-right: 6px;
        }

        .right-content {
            border-bottom-width: 0px !important;

            .entity-name {
                color: $color-title !important;
                font-family: SourceSansPro;
                font-size: 16px !important;
                line-height: 18px;
            }

            .entity-type {
                color: $color-title !important;
                font-family: SourceSansPro;
                font-size: 14px !important;
                font-weight: 300 !important;
                line-height: 15px;
            }

            .side-info {
                color: $color-caption !important;
                font-family: SourceSansPro;
                font-size: 14px !important;
                line-height: 15px;
            }
        }
    }

    .simple-search-footer {
        .spinner {
            .loadingspinner {
                background-color: transparent;
            }
        }

        .no-data {
            margin-top: 5px;
        }
    }
}

.required-field {
    color: $color-error;
    font-size: 12px;
}

// cover sub chrome
.has-own-sub-chrome {
    background-color: $color-background-primary;
    position: fixed !important;
    top: 64px;
    right: 0;
    bottom: 0;
    left: 64px;
}

.sub-chrome-container {
    align-items: center;
    display: flex;
    height: 55px;
    justify-content: space-between;
    padding: 0 16px;
    border-bottom: 1px solid $color-divider;

    .title-area {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: calc(100% - 292px); // 292 = 3 buttons width

        .focus-box {
            // 350px is the width of the combobox inside this div, align the width for both.
            width: 350px;
            align-items: center;
            margin-left: 16px;
            display: flex;
            height: 40px;
            justify-content: center;
        }
    }

    .operation-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            margin-left: 20px;
        }

        .meatball {
            font-size: 24px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            color: $brightBlue;
        }
    }

    .view-name {
        color: $color-sub-title;
    }
}

// edit page: dashboard / email digest
.config-editor-container {
    display: flex;
    flex-direction: column;

    .content {
        display: flex;
        width: 100%;
        height: calc(100% - 55px);
    }

    .left-box {
        height: 100%;
        width: calc(100% - 498px); // 498 = right-box.width

        .preview-box {
            height: calc(100% - 40px);
            height: -moz-calc(100% - 40px);
            height: -webkit-calc(100% - 40px);
        }
    }

    .right-box {
        width: 498px;
        height: 100%;
    }
}

/**No Data Style **/
.popup-template-no-data {
    text-transform: none;
    width: 100%;
    background-color: $color-panel;

    .no-data {
        font-size: 16px;
        margin-bottom: 30px;
    }
}

/**clear header style **/
.popup-template-clear-header {
    cursor: pointer;
    font-size: 14px;
    padding: 3px 12px;
    text-align: right;

    span {
        color: $color-primary-hover;
    }
}

.k-list-container .k-nodata {
    background-color: $color-panel;
}

.popup-template-no-data .link {
    color: $color-primary;
    cursor: pointer;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: $color-placeholder;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    color: $color-placeholder;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: $color-placeholder;
}

input:-ms-input-placeholder,
textarea:-moz-placeholder {
    color: $color-placeholder;
}

.config-tab-title-area {
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;

    .config-tab-title {
        letter-spacing: 1px;
        margin-left: 14px;
    }

    smart-icon {
        font-size: 24px;
    }
}

.tooltip-adjust-right {
    .k-tooltip-content {
        white-space: nowrap;
        right: 40px;
        left: auto;
    }
}

.widen-tooltip-400 {
    .k-tooltip-content {
        min-width: 400px;
    }
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.cursor-none-select {
    cursor: pointer;
    -webkit-touch-callout: default;
    -webkit-user-select: none;
}

/* ag-grid */
.ag-theme-balham .ag-header .icon-header-style {
    padding-left: 8px !important;
    padding-right: 8px !important;
    vertical-align: middle !important;
}

.ag-theme-balham .ag-header .icon-header-style .ag-header-cell-label {
    width: 100% !important;
    cursor: pointer !important;
}

.ag-theme-balham .ag-row-hover {
    background-color: $color-item-hovering !important;
}

.ag-theme-balham .ag-row-selected {
    background-color: $color-item-selected !important;
}

.ag-theme-balham .ag-row-selected::before {
    content: none !important;
}

.ag-theme-balham .ag-center-cols-viewport {
    width: 100%;
    overflow-x: inherit;
}

// remove tooltip opacity
kendo-popup .k-tooltip {
    background-color: #595959 !important;
}

.k-checkbox:checked {
    background-color: $button-bg;
    border-color: $button-bg;
}

// TAM-28033 temporary solution for known issue of kendo after upgrade
// https://github.com/telerik/kendo-themes/issues/546
$picker-select-size: #{$picker-select-calc-size}+#{$picker-select-padding-y * 2};

.k-combobox .k-dropdown-wrap .k-clear-value {
    right: calc(1.4285714286em + 14px);
}

tam-dashboard-grid-tile .ag-cell {
    // overflow: visible;
    display: block;
    overflow-wrap: break-word;
}

.no-wrap-cell {
    white-space: nowrap;
}

.wrap-cell {
    white-space: normal;
}

// when print thread list, the print dialog should has padding
@media print {
    .threaddetail-sidesheet-status {
        .content-box {
            margin: 0.2in;
            overflow: hidden !important;
        }
    }

    @page {
        size: auto;
        margin: 0;
    }

    @page Print-Preview-Dashboard-Page {
        size: auto;
        margin: 2.5mm;
    }

    #bottom-slide-sheet {
        display: none !important;
    }

    .preview-dashboard {
        page: Print-Preview-Dashboard-Page;
        -webkit-print-color-adjust: exact;

        // in edge, safari, firefox, brower print has overflow bar
        .hide-overflow-print {
            overflow: hidden !important;
        }

        &.k-dialog-wrapper {
            position: relative;

            .k-overlay {
                background-color: $color-background-primary !important;
                opacity: 1 !important;
            }

            .page-header,
            .page-header-space,
            .page-footer,
            .page-footer-space {
                display: block !important;
            }

            .preview-container {
                width: 100%;
                display: block !important;
                background: $pureWhite !important;

                .gridster {
                    width: 100% !important;
                    display: block !important;
                    margin-top: 0px !important;
                }

                .print-hide {
                    display: none !important;
                }
            }
        }
    }
}

.intralinks-container {
    .ag-cell {
        margin: 0 !important;
        height: 100% !important;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 15px !important;
        color: black;
        vertical-align: middle;
        line-height: 24px;
        margin-top: 5px !important;
    }

    .ag-center-cols-clipper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .ag-center-cols-viewport {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
}

.tam-contact-control {
    padding: 5px 4px;
}

.address-required-icon {
    margin-left: -4px;
}

.item-label-error {
    color: $color-error;
}

.template-change {
    color: $color-error;
}

.template-reload {
    color: $color-primary-click;
    cursor: pointer;
}

.map-border {
    border-radius: 2px;
    background-color: white;
    box-shadow: 0 2px 4px 0 $color-box-shadow, 0 0px 0 0 $color-box-shadow;
}

.color-link {
    color: $color-link !important;
}

.hide-icon {
    position: absolute;
    width: 10px;
    height: 10px;
    right: -1px;
    top: -7px;
    z-index: 100;
}

.hover-link {
    cursor: pointer;
}

// common width for entity extra information
.col-sm-2 {
    width: 110px;
}

.col-sm-10 {
    width: calc(100% - 110px - 24px);
}

.dropdown-fly-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 32px;
    padding: 16px;
    width: 100%;

    span {
        color: $color-link;
    }
}

.popup-dashboard-view-sub-chrome.k-popup {
    background-color: $White;
}

.div-flex {
    display: flex;
}

/* a global class, with this class an angle-down icon would be appended right after the decorated element. */
.dropdown-link {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.dropdown-link:after {
    margin-left: 2px;
    content: '';
    display: block;
    width: 12px;
    height: 6px;
    background-image: url("./assets/images/angle_down.svg");
}

agm-map {
    height: 1px;
    visibility: hidden;
}

.signature-status {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.disable-element {
    pointer-events: none;
}

// layer template
.single-layer-content {
    margin-left: 6px;
    margin-right: 6px;
}

.signature-status {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.light-color {
    color: $linkColor;
}
