/**
 * created by yxzhang on 07/28/16
 * Description: put all color variables here, put them in right block
 *
 * Updated by Alan Yang on 11/02/2018
 * Description: unify sass color with BA.
 * Updated by Ella Ma on 11/07/2019 Story TAM-27326 Modify the place where transparent colors are used
 * 7/26/2021 Simon Zhao add two new color variables.
 */

///////////////////////////////////////////////////////
//
//  general colors
//
///////////////////////////////////////////////////////
$black: #282828;
$pureWhite: #ffffff;
$highlight: #06bee1;
$secondaryGrey: #bfbfbf;
$midGrey: #979797;
$darkGrey: #808080;
$lightGrey: #f2f2f2;
$iron: #d8d8d8;
$warmGrey: #929292;
$ultraLightGrey: #efefef;
$brightBlue: #007aff;
$brightBlue2: #1e71d5;
$brightBlue3: #1890ff;
$blackinshGreen: #05a1c1;
///////////////////////////////////////////////////////
//
// Tamale Color System (Dev should NOT use it in code directly)
// To find the name for a given color: https://colors.artyclick.com/color-name-finder/
///////////////////////////////////////////////////////
$PickledBluewood: #2a3d53;
$Fiord: #415161;
$RoyalBlue: #307fe2;
$CornflowerBlue: #59a2f0;
$Denim: #1e60bd;
$RadicalRed: #f53249;
$PaleRed: #fdccd2;
$WildWatermelon: #ff5c69;
$Cardinal: #cf1f39;
$FruitSalad: #48a651;
$RawSienna: #da8945;
$PattensBlue: #d9efff;
$LightBlue: #e7eff5;
$AliceBlue: #f0f9ff;
$Black: #000000;
$BlackD9: #000000d9;
$Black73: #00000073;
$White: #ffffff;
$PacificBlue: #05a1c1;
$Buttercup: #f5a623;
$GrayChateau: #abb2b9;
$Buttercup: #f1c40f;
$LightGray: #b3b3b3;
$LightGray2: #eeeeee;
$LightGray3: #cccccc;
$LightGreen: #69b36e;
$DarkGreen: #30803b;
$LightSteelBlue: #c4d6ed;
$Cadetblue: #95a5a6;
$ShadowPink: #fcb7bf;
$lightwhite: #eaf2fc;
$BarneyPurple: #a9008a;
$BackPink: #fef2f2;
///////////////////////////////////////////////////////
//
// Tamale Color Config (Start with prefix 'color-')
//
///////////////////////////////////////////////////////
$color-theme: $PickledBluewood; //Brand theme color
$color-link: $RoyalBlue; //Hyperlink color
$color-primary: $RoyalBlue; //Primary color for major action.
$color-primary-hover: $CornflowerBlue; //Hover color based on 'Primary'
$color-primary-dashed: rgba($CornflowerBlue, 0.08); //dashed background color '
$color-primary-click: $Denim; //Click color based on 'Primary'
$color-background-primary: $White; //Primary Color for background
$color-primary-success: $FruitSalad; //Primary success color for major action.
$color-primary-success-hover: $LightGreen; //Hover color based on 'Primary'
$color-primary-success-click: $DarkGreen; //Click color based on 'Primary'
$color-background-primary-success: $White; //Primary Color for background
$color-secondary: $RoyalBlue; //Secondary color for major action
$color-secondary-hover: $CornflowerBlue; //Hover color based on 'Secondary'
$color-secondary-click: $Denim; //Click color based on 'Secondary'
$color-tertiary: rgba($Black, 0.65); //Tertiary color for major action
$color-tertiary-border: #d9d9d9; // rgba($Black, 0.15); //Border color based on 'Tertiary'
$color-tertiary-hover: rgba($Black, 0.45); //Hover color based on 'Tertiary'
$color-tertiary-click: rgba($Black, 0.85); //Click color based on 'Tertiary'
$color-shadow-horizontal: rgba($Black, 0.2); //Click color based on 'Tertiary'
$color-shadow-vertical: rgba($Black, 0.1); //Click color based on 'Tertiary'
$color-shadow-border: rgba($Black, 0.13);
$color-outline-border: rgb(0, 95, 204); // outline-color of multi-sel-dropdown-filter
$color-error: $RadicalRed; //Error & Danger color for major action
$color-error-hover: $WildWatermelon; //Hover color based on 'Error'
$color-error-click: $Cardinal; //Click color based on 'Error'
$color-error-background: $PaleRed; // error background
$color-event-icon-background: #FFBB80; // event icon background
$color-success: $FruitSalad; //Sucess color
$color-warning: $RawSienna; //Error / dangers color
$color-item-title-background: #F5F5F5; //Item title color
$color-item-selected: $PattensBlue; //Item selected color
$color-item-hover: rgba($PattensBlue, 0.25); //Item selected color
$color-item-passive-selected: $LightBlue; // Item passive selected
$color-item-passive-selected-box-shadow: $LightGray; // Item passive selected box shadow
$color-item-hovering: $AliceBlue; //Item hovering color
$color-info: $PacificBlue; //Information color
$color-border: #d9d9d9; // rgba($Black, 0.15); //Neutral Color for border
$color-keyboard-border: $Black;
$color-border-focus: $RoyalBlue; //The border color when focus on it, like hover or focus on an input box etc
$color-border-error: $RadicalRed; //The border color when error occurs on an input box etc
$color-background-secondary: #f5f5f5; // rgba($Black, .04); //Neutral Color for background
$color-divider: #e8e8e8; // rgba($Black, .09); // Neutral Color for divider
$color-positive: $FruitSalad; //Tamale color for positive notes
$color-negative: $RadicalRed; //Tamale color for negative notes
$color-priority: $Buttercup; //Tamale color for priority star icon
$color-lock: $Buttercup; //Tamale color for private lock icon
$color-progress-error: rgba($Buttercup, 0.65);
$color-menuItem-hover: $Fiord; //Tamale color for hovering on an item on menu
$color-icon-default-light: rgba($Black, 0.65); //Default icon color on light background
$color-icon-hover-light: $RoyalBlue; //Hover icon color on light background
$color-icon-disabled-light: rgba($Black, 0.25); //Disabled icon color on light background
$color-icon-default-dark: rgba($White, 0.85); //Default icon color on dark background. E.G. + button on top chrome
$color-box-shadow12: rgba($Black, 0.12); // Box shadow 0.12 based on Black
$color-box-shadow24: rgba($Black, 0.24); // Box shadow 0.24 based on Black
$color-box-shadow: rgba($Black, 0.35); // Box shadow
$color-box-shadow02: rgba($Black, 0.02); // Box shadow 0.02 based on Black
$color-box-shadow-light: rgba($Black, 0.09); // Box shadow light
$color-caption: rgba($Black, 0.45); // Caption text color
$color-title: rgba($Black, 0.85); // Title text color
$color-content: rgba($Black, 0.65); // Title text color
$color-footnote: $GrayChateau;
$color-text-highlight: $Buttercup;
$color-display-number-light: rgba($Black, 0.25); //Number only for display color on light background
$color-text-light: rgba($Black, 0.25);
$color-checkbox-border: $darkGrey;
$color-placeholder: rgba($Black, 0.25); // placeholder color
$color-sub-title: $warmGrey;
$color-column-group-border: $secondaryGrey;
$color-drgable-icon: $secondaryGrey;
$color-todo-background: #d9d9d9; // rgba($Black, 0.15);
$color-background-field: #d9d9d9; // rgba($Black, 0.15);
$color-background-disabled-input: $LightGray2; // Background color for disabled inputs
$color-tag-background: $lightwhite; // rgba(48, 127, 226, 0.15)
$color-todo: #595959; // rgba($Black, 0.65);
$color-undo: #98bff1;
$color-approve: $FruitSalad;
$color-approve-background: #dff1e0;
$color-rejected: red;
$color-rejected-background: pink;
$color-done: $FruitSalad;
$color-done-background: #dff1e0; // $color-done .15
$color-inprogress: $RawSienna;
$color-inprogress-background: #f9e6df; // $color-inprogress .15
$color-panel: $White;
$color-alert-info-icon: $blackinshGreen;
$color-progress-bar-background: $secondaryGrey;
$color-task-overdue: $RadicalRed;
$color-required-symbol: $RadicalRed; // Color for the * simbol after the title of required inputs
$color-fieldset-border: #00000017;
$color-mdl-provider: $BlackD9;
$color-mdl-label: $Black73;
$color-search-input-boarder: $Cadetblue;
$color-bottom-split-line: rgba($Black, 0.25);
$color-search-box-shadow: #b7d2f5; // Color for search boxes in admin console page
$color-border-click: (
    $lightwhite,
    0.65
);
$color-progress-border: rgba($Black, 0.45); // the border color of inactive nodes in progress list
$color-input-dash-border: $LightGray3;
$color-control-inactive: $secondaryGrey;
///////////////////////////////////////////////////////
//
// component colors
//
///////////////////////////////////////////////////////
// note metadata theme on Native app
$dividerColor: #b5b5b5;
$borderColor: $midGrey;
$starRating: #f1c40f;
$positive: #4caf50;
$negative: #f44336;
$numberLabel: #abb2b9;
$tagTextColor: #4692de;
$numberBubbleColor: #858585;
$linkColor: #4a90e2;
$hilightItemColor: #06bbe1;
// link
$hyperlink: $brightBlue2;
$disabledColor: $ultraLightGrey;
// fonts
$sourceSansProFont: "SourceSansPro";
$semiBoldFont: "SourceSansPro-Semibold";
$completeNodeBackgroundColor: $FruitSalad;
$completeNodeForecolor: $pureWhite;
$unreachPathLineColor: $iron;
$progressStageNameColor: $BarneyPurple;
$defaultTaskNameColor: $color-title;
$inactiveTaskNameColor: $color-caption;
$checkItemBackgroundColor: $pureWhite;
$workflowFieldForeground: $color-title;
$button-background: $brightBlue3;